<template>
  <div>
    <ul class="solution-warp">
      <li v-for="(item, i) in list" :key="`solution` + i" class="solution-item" @click="goto(item.url)">
        <div class="a"></div>
        <el-image class="w100" :src="item.image"></el-image>
        <div class="solution-item-content">
            <div class="title">
              <h3>{{item[lang + 'title']}}</h3>
            </div>
            <p class="desc">{{item[lang + 'desc']}}</p>
            <a class="more" :to="{name:'solutionDetail',params:{ id: item.url}}">{{$t('page.home.more')}}></a>
        </div>
      </li>
    </ul>

    <el-popover
        placement="left"
        trigger="click"
        width="160"
        style="height: 160px;">
      <div class="wxCode">
        <h3>扫码联系专属客服</h3>
        <p>一对一为您答疑解惑</p>
        <el-image :src="options.customer_service"></el-image>
        <a>扫码立即联系</a>
        <p style="font-size: 12px">联系电话: 86-769-85613339</p>
      </div>

      <div class="customer-service"  slot="reference">
        <i class="el-icon-service"></i>
        <p>{{$t('page.console.menu.contact_us')}}</p>
      </div>
    </el-popover>
  </div>
</template>


<script>
import {getOption} from "@/api/os/admin";

export default {
  data() {
    return {
      list: [],
      options: []
    }
  },
  created() {
    this.get()
  },
  computed: {
    // add
    lang() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    }
  },
  methods: {
    get() {
      this.loading = true
      getOption({
        name: 'page-solution'
      }).then(res => {
        this.loading = false
        this.list = res.data.data
      })

      getOption({
        name: 'page-home',
      }).then(res => {
        if(res.data.data) {
          this.options = res.data.data
        }
      })
    },
    goto(id) {
      this.$router.push({
        name: 'solutionDetail',
        params: {
          id: id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.solution-warp{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-color: #b8e5f8;
  background-size: cover;
  width: 100%;
  //height: 100vh;
  //overflow: auto;
  .el-image{
    img{
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}
.solution-item{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  height: calc(100% - 42px);
  box-sizing: border-box;
  position: relative;
  .el-image {
    //height: 600px;
  }
  .a{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0,0,0,.5);
    z-index: 1;
    display: none;
  }
  .solution-item-content{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);
    border-radius: 2px;
    //background-color: #FFFFFF;
    width: 50%;
    overflow: hidden;
    position: absolute;
    color: #ffffff;
    text-align: center;
    z-index: 2;
      .title{
        background: rgba(11, 11, 11, 0.63);
        padding: 20px;
        font-size: 40px;
      }
      .desc{
        font-size: 1.2rem;
        margin: 30px auto;
      }
      .more{
        border: 2px solid #ffffff;
        padding: 8px 16px;
        font-weight: 800;
        color: #ffffff;
      }
  }
}

.wxCode{
  text-align: center;
  p{
    color: #787878;
    margin: 10px auto;
  }
}
.customer-service{
  position: fixed;
  right: calc( (100% - 1200px) / 2 - 100px);
  bottom: 16%;
  z-index: 99;
  background: #ffffff;
  color: #00923F;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(20, 221, 107, 0.5);
  .el-icon-service{
    font-size: 50px;
  }
}
</style>